@import "../../../../../colors";

.BusinessInfo {
  position: relative;

  small {
    color: #777;
    font-size: 12px;

    a {
      color: $alternateColor;
    }
  }

  .input-row {
    display: flex;
    flex-direction: row;

    .input-container {
      &:first-child {
        margin-right: 5px;
      }

      &:last-child {
        margin-left: 5px;
      }

      &.flex-1 {
        flex: 1;
      }

      &.flex-2 {
        flex: 2;
      }
    }

    &.phone-row {
      .timezone-container {
        margin-right: 10px;
      }

      @media (max-width: 1100px) {
        flex-direction: column;

        .timezone-container {
          margin-right: 0;
        }
      }
    }
  }

  button {
    width: 100%;
    margin-top: 5px;
  }
}
