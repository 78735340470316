@import "../../../colors";

.BusinessNotFound {
  position: relative;

  .text-container {
    width: 630px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 70px;
    text-align: center;
  }
}
