.StaffActionButton {

  button {
    height: 20px;
    width: 20px;
    border-radius: 20px;
    padding: 0;
    background-color: rgba(0, 0, 0, 0);

    .fas {
      color: rgba(0, 0, 0, 0.8);
    }
  }
}
