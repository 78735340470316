@import "../../../colors";

.Onboarding {
  background-color: #fefefe;
  padding: 10px;

  h1 {
    text-align: center;
    font-size: 26px;
    font-weight: 400;
    margin-top: 30px;
  }

  .onboarding-container {
    max-width: 800px;
    margin: 25px auto 50px;
    background-color: #fff;

    .stepper-container {
      display: flex;
      flex-direction: row;

      .step-container {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: rgba(0, 0, 0, 0.45);

        &.last-step-container {
          width: 120px;
          flex: none;
        }

        .flex-number-container {
          height: 30px;
          width: 30px;
          border-radius: 30px;
          background-color: rgba(0, 0, 0, 0.05);
          text-align: center;
          line-height: 30px;
          margin-right: 10px;
        }

        p {
          margin-right: 10px;
        }

        .filler-line {
          flex: 1;
          border-top: 1px solid $borderLight;
          margin-right: 10px;
        }

        &.complete {
          color: #000;

          .flex-number-container {
            background-color: $successColor;
            color: #fff;
          }
        }

        &.active {

          .flex-number-container {
            background-color: $primaryColorDark;
          }
        }
      }

      @media (max-width: 750px) {
        flex-direction: column;

        .step-container .filler-line {
          display: none;
        }
      }
    }
  }
}
