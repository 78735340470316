@import "../../../../colors";

.Overview {
  padding: 15px;

  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    h1 {
      font-size: 20px;
      font-weight: 400;
      margin: 13px 0;
    }

    @media (max-width: 470px) {
      flex-direction: column;
    }

    .search-container {
      width: 350px;
      position: relative;

      @media (max-width: 500px) {
        margin-left: 20px;
        width: initial;
        flex: 1;
      }

      i {
        position: absolute;
        top: 17px;
        left: 13px;
        color: #200E32;
      }

      input {
        padding-left: 37px;
      }
    }

    @media (max-width: 470px) {
      flex-direction: column;
      align-items: flex-start;

      .search-container {
        margin-left: 0;
        width: 100%;
        margin-top: 20px;
      }
    }
  }

  .top-row {
    display: flex;
    flex-direction: row;
    align-items: center;

    .top-container {
      padding: 20px;
      border-radius: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 228px;

      &.success {
        background-color: $successColorBright;
      }

      &.danger {
        background-color: $dangerColorLight;
        margin-left: 20px;
      }

      .image-container {
        background-color: #fff;
        height: 63px;
        width: 63px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin-right: 13px;

        img {
          width: 50%;
        }
      }

      p {
        margin: 0;
        font-size: 14px;

        &.bottom-text {
          font-weight: 600;
        }
      }
    }

    .welcome-container {
      height: 103px;
      margin-left: 20px;
      border-radius: 20px;
      border: 1px solid $borderDark;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 1;
      justify-content: space-between;

      .welcome-text {
        width: 170px;
        margin: 0 20px;
        overflow: hidden;

        p {
          margin: 0;
          font-size: 18px;
          font-weight: 600;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .welcome-images-container {
        display: flex;
        flex-direction: row;

        .welcome-image-container {
          height: 90px;
  
          img {
            height: 100%;
          }
        }
      }

      @media (max-width: 1170px) {
        .welcome-image-container.first {
          display: none;
        }
      }

      @media (max-width: 1035px) {
        .welcome-image-container.second {
          display: none;
        }
      }

      @media (max-width: 900px) {
        .welcome-image-container.second {
          display: initial;
        }
      }

      @media (max-width: 835px) {
        .welcome-image-container.second {
          display: none;
        }
      }
    }

    @media (max-width: 750px) {
      .top-container {
        width: auto;
        flex: 1;
      }

      .welcome-container {
        display: none;
      }
    }

    @media (max-width: 500px) {
      flex-direction: column;

      .top-container {
        width: 100%;

        &.danger {
          margin-left: 0;
          margin-top: 15px;
        }
      }
    }
  }

  .appointments-container {
    margin-top: 20px;
  }

  .no-appointments-container {
    border: 1px solid $borderLight;
    background-color: $primaryColorMedium;
    padding: 20px;
    border-radius: 20px;
    margin-top: 20px;

    p {
      margin: 0;
      font-weight: 600;
      font-size: 14px;
    }
  }
}
