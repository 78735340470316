.Sidebar {
  position: relative;

  .bottom-border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    width: 120px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;

    &.first {
      margin-top: 5px;
    }

    @media (max-width: 900px) {
      width: calc(100% - 56px);
    }
  }

  a {
    text-decoration: none;

    .nav-item {
      color: #333;
      text-decoration: none;
      padding-right: 14px;
      font-weight: bold;
      font-size: 14px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;

      .nav-active-item {
        width: 5px;
        height: 40px;
        margin-top: 2px;
        margin-bottom: 2px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;

        &.active {
          background-color: #7E60F5;
        }
      }

      i {
        margin-right: 15px;
        margin-left: 35px;
      }

      &:hover {
        opacity: 0.85;
      }

      &.sign-out {
        margin-bottom: 20px;
      }
    }
  }
}
