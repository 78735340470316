@import "../../../colors";

.Home {
  position: relative;

  .header-container {
    @media (min-width: 900px) {
      display: none;
    }
  }

  .main-container {
    display: flex;
    flex-direction: row;

    .nav-container {
      width: 240px;
      position: relative;
      z-index: 10;
      background-color: #fff;
      min-height: 100vh;
      z-index: 11;

      .nav-inner {
        position: fixed;
        height: 100vh;
        width: 240px;
        left: 0;
        top: 0;
        background-color: $primaryColor;
        transition: transform 0.2s;
        display: flex;
        flex-direction: row;

        .nav-content-container {
          width: 200px;
          overflow-y: auto;

          .logo-container {
            text-align: center;
            height: 64px;
  
            .logo {
              height: 30px;
              margin-top: 20px;
              filter: brightness(0%);
            }
  
            .close-container {
              display: none;
            }
          }
        }

        .rounded-sidebar {
          background-color: #fff;
          width: 40px;
          border-radius: 40px 0 0 40px;
          height: 100%;
          position: fixed;
          left: 201px;
        }
      }

      @media (max-width: 900px) {
        width: 200px;

        .nav-inner {
          width: 200px;

          .rounded-sidebar {
            width: 0;
          }
        }
      }
    }

    .content-container {
      padding-bottom: 70px;
      left: 200px;
      right: 0;
      top: 0;
      bottom: 0;
      position: fixed;
      z-index: 12;
      overflow-y: auto;
      border-radius: 40px 0 0 40px;
  
      .content-inner {
        width: 100%;
      }
    }
  
    @media (max-width: 900px) {
      .content-container {
        width: 100%;
        position: relative;
        left: 0;
        z-index: 0;
      }

      .nav-container {
        width: 0;

        .nav-inner {
          width: 100%;

          &.closed {
            transform: translateX(-100%);
          }

          .nav-content-container {
            width: 100%;

            .logo-container {
              text-align: left;
              display: flex;
              flex-direction: row;
              align-items: center;
              height: 64px;
    
              .nav-logo-container {
                display: flex;
                flex: 1;

                .logo {
                  height: 30px;
                  margin-top: 0;
                  filter: brightness(0%);
                  flex: 1;
                  margin-left: -58px;
                }
              }

              .close-container {
                display: flex;
                flex: 1;
                
                .close {
                  font-size: 27px;
                  cursor: pointer;
                  margin-right: 21px;
                  margin-left: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}
