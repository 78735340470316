@import "../../../../colors";

.OnboardingStep4 {
  margin-top: 15px;

  .top-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;

    h2 {
      font-size: 20px;
      font-weight: 600;
    }

    .add-button-container {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
  }

  p {
    margin-top: 0;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .service-container {
    border-top: 1px solid $borderLight;
    margin-top: 20px;

    .service-top-row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h3 {
        font-size: 18px;
        font-weight: 600;
      }
  
      .delete-button {
        height: 44px;
        width: 44px;
        border-radius: 44px;
        padding: 12px;
        background-color: rgba(0, 0, 0, 0.05);
        margin-left: 10px;
    
        .fa-trash {
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }

    .input-row {
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      .flex-2 {
        flex: 2;
        margin-right: 10px;
      }

      .flex-1 {
        flex: 1;
      }
    }
  }

  .actions-row {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $borderLight;
    padding-top: 20px;

    a {
      cursor: pointer;
    }
  }
}
