.OnboardingStep1 {
  position: relative;

  .input-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .input-container {
      width: 49%;
    }

    @media (max-width: 410px) {
      flex-direction: column;

      .input-container {
        width: 100%;
      }
    }
  }

  .actions-row {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    a {
      cursor: pointer;
    }
  }
}
