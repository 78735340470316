@import "../../../../colors";

.Staff {
  padding: 15px;

  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    h1 {
      font-size: 20px;
      font-weight: 400;
      margin: 0;
    }

    button {
      background-color: #fff;
      color: #18181A;
      font-weight: 400;
      border: 1px solid $borderLight;
      padding: 14px 16px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.03);
      }

      .fas {
        margin-left: 10px;
        font-size: 15px;
      }
    }
  }
}
