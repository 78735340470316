@import "../../../../../colors";

.CalendarHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;

  button {
    background-color: #fff;
    color: #18181A;
    font-weight: 400;

    &:hover {
      background-color: rgba(0, 0, 0, 0.03);
    }
  }

  .header-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;

    h1 {
      font-size: 20px;
      font-weight: 400;
      margin: 0;
    }

    .view-toggle {
      margin-left: 15px;
      border: 1px solid $borderLight;
      border-radius: 12px;
      padding: 1px;

      button {
        opacity: 0.5;

        &.active {
          background-color: $successColorBright;
          opacity: 1;
        }
      }
    }
  }

  .date-selector-container {

    .date-selector {
      border: 1px solid $borderLight;
      border-radius: 12px;
      display: flex;
      flex-direction: row;

      button {

        .fas {
          font-size: 15px;
        }
      }

      .date-display {
        padding: 12px 0;
        width: 150px;
        text-align: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  .header-right {
    flex: 1;
    display: flex;
    flex-direction: row-reverse;

    button {
      border: 1px solid $borderLight;

      .fas {
        margin-left: 10px;
        font-size: 15px;
      }
    }
  }

  @media (max-width: 1100px) {
    display: block;
    clear: both;
    padding-bottom: 35px;

    .header-left {
      justify-content: space-between;
      margin-bottom: 20px;
    }

    .date-selector-container {
      display: block;
      float: left;
    }

    .header-right {
      display: block;
      float: right;
    }
  }

  @media (max-width: 510px) {
    .date-selector-container .date-selector .date-display {
      width: 125px;
    }
  }

  @media (max-width: 480px) {
    padding-bottom: 0;

    .date-selector-container {
      float: none;
      margin-bottom: 20px;
      
      .date-selector {
        display: flex;
        flex-direction: row;

        .date-display {
          flex: 1;
        }
      }
    }

    .header-right {
      float: none;

      button {
        width: 100%;
      }
    }
  }
}