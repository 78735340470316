@import "../../../../../colors";

.CalendarDayView {
  width: 100%;
  display: flex;
  flex-direction: row;
  border: 1px solid $borderDark;
  border-radius: 20px;
  overflow: hidden;
  background-color: $primaryColorLight;

  .staff-column {

    .staff-header {
      background-color: $primaryColorMedium;
      padding: 20px 25px;
      border-right: 1px solid $borderLight;

      p {
        margin: 0;
        font-weight: 600;
        font-size: 14px;
      }
    }

    .staff-member {
      background-color: $primaryColorLight;
      padding: 0 10px;
      border-top: 1px solid $borderLight;
      border-right: 1px solid $borderLight;
      height: 80px;
      width: 225px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .staff-image-container {
        background-color: #eee;
        border-radius: 50%;
        height: 44px;
        width: 44px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-right: 10px;

        i {
          align-self: center;
          font-size: 28px;
          cursor: pointer;
          color: #000;
        }
      }

      .staff-details-container {
        flex: 1;
        width: 150px;

        p {
          margin: 0;
          overflow: hidden;
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;

          &.staff-name {
            font-weight: 600;
            margin-bottom: 1px;
          }

          &.staff-appointment-count {
            font-size: 12px;
          }
        }
      }

      @media (max-width: 500px) {
        width: 160px;

        .staff-image-container {
          height: 30px;
          width: 30px;
          margin-right: 7px;

          i {
            font-size: 20px;
          }
        }

        .staff-details-container {
          width: 100px;

          .staff-name {
            font-size: 12px;
            margin-bottom: 2px;
          }

          .staff-appointment-count {
            font-size: 10px;
          }
        }
      }
    }
  }

  .time-columns {
    flex: 1;
    overflow-x: auto;
    display: flex;
    flex-direction: row;

    .time-column {

      .time-header {
        background-color: $primaryColorMedium;
        border-right: 1px solid $borderLight;
        padding: 20px 25px;
        width: 125px;
        text-align: center;

        p {
          margin: 0;
          font-weight: 600;
          font-size: 14px;
        }
      }

      .staff-time-block {
        background-color: $primaryColorLight;
        height: 80px;
        width: 125px;
        border-top: 1px solid $borderLight;
        border-right: 1px solid $borderLight;
        display: flex;
        flex-direction: row;
        position: relative;

        .time-block-select-button {
          padding: 0;
          flex: 1;
          border-radius: 5px;
          background-color: rgba(0, 0, 0, 0);

          &:hover {
            background-color: rgba(0, 0, 0, 0.05);
          }
        }

        .appointment-button {
          position: absolute;
          overflow: hidden;
          text-align: left;
          top: 0;
          bottom: 0;
          z-index: 1;
          padding: 5px;
          display: flex;

          span {
            font-size: 13px;
            display: block;
            width: 100%;

            &.appointment-time {
              margin-bottom: 5px;
              overflow: hidden;
              width: 100%;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }


      &:last-of-type {

        .staff-time-block,
        .time-header {
          border-right-width: 0;
        }
      }
    }
  }
}
