$primaryColor: #E8E2FF;
$primaryColorDark: #6F66A8;
$primaryColorLight: #F5F6FD;
$primaryColorMedium: #EAECFB;
$primaryColorVibrant: #7E60F5;
$secondaryColor: #FFD040;
$secondaryColorLight: #FDF9F5;
$secondaryColorMedium: #FDEEDF;
$successColor: #2BB673;
$successColorLight: #FBFDF5;
$successColorMedium: #EDF2DC;
$successColorBright: #ECFAE5;
$alternateColor: #a6c4e7;
$dangerColor: #E64B38;
$dangerColorLight: #FFECEA;
$dangerColorMedium: #FFB4AB;
$borderLight: rgba(0, 0, 0, 0.1);
$borderDark: rgba(0, 0, 0, 0.2);
$transparent: rgba(0, 0, 0, 0);
$linkColor: #0e70be;
