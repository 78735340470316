@import "../../../../colors";

.OnboardingStep2 {
  position: relative;
  overflow-y: auto;
  margin-top: 15px;
  
  .top-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;

    h2 {
      font-size: 20px;
      font-weight: 600;
    }
  }

  p {
    margin-top: 0;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  table {
    border-collapse: collapse;
  }

  thead {
    font-weight: bold;
  }

  td {
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid $borderLight;
  }

  input[type="checkbox"] {
    width: auto;
  }

  .active-cell {
    padding-left: 20px;
    padding-right: 20px;
  }

  tbody .active-cell {
    padding-left: 35px;
  }

  .time-cell {
    width: 160px;

    .time-container {
      display: flex;
      flex-direction: row;

      select {
        padding: 5px 10px;
        background: linear-gradient(45deg, transparent 50%, #000 50%), linear-gradient(135deg, #000 50%, transparent 50%), linear-gradient(to right, #fff, #fff);
        background-position: calc(100% - 21px) calc(1em + -1px), calc(100% - 16px) calc(1em + -1px), 100% 0;
        background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
        background-repeat: no-repeat;

        &:first-of-type {
          width: 80px;
          border-right: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        &:last-of-type {
          width: 70px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }

  .actions-row {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    a {
      cursor: pointer;
    }
  }
}
