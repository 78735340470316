@import "../../../colors";

.SchedulePicker {
  background-color: #fbfcfd;
  min-height: 100vh;
  padding: 75px 0;

  .scheduled-message-container {
    width: 800px;
    max-width: calc(100vw - 40px);
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    .details-container {
      width: 800px;
      max-width: calc(100vw - 40px);
      margin-left: auto;
      margin-right: auto;
      border: 1px solid $borderDark;
      text-align: left;
      border-radius: 20px;
      background-color: #fff;
      padding: 20px;
    }
  }

  .content-container {
    width: 800px;
    max-width: calc(100vw - 40px);
    margin-left: auto;
    margin-right: auto;
    border: 1px solid $borderDark;
    border-radius: 20px;
    background-color: #fff;
    display: flex;
    flex-direction: row;

    .left-container {
      flex: 1;
      padding: 20px;
      border-right: 1px solid $borderDark;

      p {
        color: rgba(26, 26, 26, 0.61);
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        margin: 0;

        &.duration {
          margin-bottom: 10px;
        }
      }

      h1 {
        margin: 0;
        font-weight: 700;
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 30px;
      }
    }

    .right-container {
      flex: 1;
      padding: 20px;

      .back-button {
        padding: 0;
        margin-bottom: 10px;
        background-color: #fff;
        color: $linkColor;
        font-size: 14px;

        i {
          margin-right: 7px;
        }
      }

      h2 {
        margin-top: 0;
        margin-bottom: 20px;
        font-weight: 700;
        font-size: 20px;
      }

      .staff-picker {

        button {
          background-color: #fff;
          color: #18181A;
          font-weight: 400;
          border: 1px solid $borderLight;
          padding: 14px 16px;
          width: 100%;
          margin-bottom: 10px;

          &:hover {
            background-color: rgba(0, 0, 0, 0.03);
          }
        }
      }

      form {
        button {
          width: 100%;
          margin-top: 5px;
        }
      }

      .time-picker-container {
        .times-container {
          height: 400px;
          display: flex;
          flex-direction: column;

          .time-buttons-container {
            flex: 1;
            margin-bottom: 10px;
            border-bottom: 1px solid $borderLight;
            overflow-y: auto;

            button {
              background-color: #fff;
              color: #18181A;
              font-weight: 400;
              border: 1px solid $borderLight;
              padding: 14px 16px;
              width: 100%;
              margin-bottom: 10px;

              &:hover {
                background-color: rgba(0, 0, 0, 0.03);
              }
            }
          }

          p {
            margin: 0;
            font-size: 14px;
          }
        }
      }

      .date-picker-container {
        width: 100%;
        margin-top: 30px;

        button:disabled {
          background-color: #fff !important;
        }

        .react-calendar {
          border-radius: 10px;
          padding: 10px;
          max-width: 350px;
          min-width: 280px;
          margin-left: auto;
          margin-right: auto;

          button {
            padding: 0;
            margin: 0;
            background-color: rgba(0, 0, 0, 0);
            color: #0A0A0A;
          }

          .react-calendar__navigation {
            display: flex;
            flex-direction: row;
            padding-left: 8px;
            padding-right: 8px;
            width: calc(100% - 15px);
            max-width: 307px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 25px;

            .react-calendar__navigation__prev2-button,
            .react-calendar__navigation__next2-button {
              display: none;
            }

            .react-calendar__navigation__label {
              pointer-events: none;

              .span {
                font-weight: 600;
                font-size: 16px;
              }
            }

            .react-calendar__navigation__arrow i {
              font-size: 14px;
            }
          }

          .react-calendar__viewContainer .react-calendar__month-view {
            margin-top: 10px;

            .react-calendar__month-view__weekdays {
              text-align: center;
              margin-bottom: 3px;
              padding-bottom: 3px;
              border-bottom: 1px solid #F5F5F5;

              abbr {
                font-size: 0;
                display: block;
                font-weight: 600;
                text-decoration: none;

                &:first-letter {
                  font-size: 12px;
                }
              }
            }

            .react-calendar__month-view__days {

              button {
                font-size: 12px;
                border-radius: 50%;
                height: 45px;
                background-color: $primaryColorDark;
                color: #fff;

                &:disabled {
                  color: #0A0A0A;
                }

                &:hover {
                  opacity: 0.9;
                }

                &.date-selected-for-override {
                  background-color: $primaryColorVibrant;
                  color: #fff;

                  &:hover {
                    background-color: $primaryColorVibrant;
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
    }

    @media (max-width: 750px) {
      flex-direction: column;

      .left-container {
        flex: 1;
        padding: 20px;
        border-bottom: 1px solid $borderDark;
        border-right-width: 0;
      }
    }
  }
}