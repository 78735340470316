@import "../../../../colors";

.Account {
  padding: 15px;

  .header-container {
    margin-bottom: 30px;

    h1 {
      font-size: 20px;
      font-weight: 400;
      margin: 4px 0 0;
    }
  }

  .tabs-container {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    align-items: stretch;
    border-bottom: 1px solid $borderLight;

    .nav-tab {
      padding: 10px 8px 0;
      margin: 0 7px;
      font-size: 14px;
      font-weight: 600;
      color: #333;
      text-align: center;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .tab-highlighter {
        height: 3px;
        width: 100%;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        margin-top: 7px;
      }

      &.active {
        color: $primaryColorDark;

        .tab-highlighter {
          background-color: $primaryColorDark;
        }
      }
    }
  }

  .tab-content {
    padding: 15px;
  }
}
