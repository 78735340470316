@import "../../../../../colors";

.CalendarWeekView {
  width: 100%;
  display: flex;
  flex-direction: row;
  border: 1px solid $borderDark;
  border-radius: 20px;
  overflow: hidden;
  background-color: $primaryColorLight;
  margin-bottom: -70px;

  .left-column {
    width: 200px;

    @media (max-width: 600px) {
      width: 160px;
    }

    .left-column-header {
      background-color: $primaryColorMedium;
      height: 60px;
      border-bottom: 1px solid $borderLight;
    }

    .left-column-content {
      height: calc(100vh - 235px);
      min-height: 500px;
      overflow-y: auto;

      .calendar-picker-container {
        padding: 10px;

        .react-calendar {
          background-color: #fff;
          border-radius: 10px;
          padding: 10px;

          button {
            padding: 0;
            margin: 0;
            background-color: rgba(0, 0, 0, 0);
            color: #0A0A0A;
          }

          .react-calendar__navigation {
            display: flex;
            flex-direction: row;
            padding-left: 8px;
            padding-right: 8px;

            .react-calendar__navigation__prev2-button,
            .react-calendar__navigation__next2-button {
              display: none;
            }

            .react-calendar__navigation__label span {
              font-weight: 600;
              font-size: 11px;
            }

            .react-calendar__navigation__arrow i {
              font-size: 10px;
            }
          }

          .react-calendar__viewContainer .react-calendar__month-view {
            margin-top: 10px;

            .react-calendar__month-view__weekdays {
              text-align: center;
              margin-bottom: 3px;
              padding-bottom: 3px;
              border-bottom: 1px solid #F5F5F5;

              abbr {
                font-size: 0;
                display: block;
                font-weight: 600;
                text-decoration: none;

                &:first-letter {
                  font-size: 10px;
                }
              }
            }

            .react-calendar__month-view__days {

              button {
                font-size: 10px;
                border-radius: 8px;

                &:hover {
                  background-color: rgba(0, 0, 0, 0.05);
                }

                &.react-calendar__tile--rangeBothEnds {
                  background-color: $primaryColorVibrant;
                  color: #fff;

                  &:hover {
                    background-color: $primaryColorVibrant;
                    opacity: 1;
                  }
                }

                &.react-calendar__month-view__days__day--neighboringMonth {
                  color: #70757a;
                }
              }
            }
          }
        }
      }

      .staff-container {

        .staff-member {
          width: 200px;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 20px 0;
          background-color: $primaryColorLight;
          padding: 0;
          border-radius: 0;
          text-align: left;
          color: #000;

          .selected-indicator {
            margin-right: 7px;
            width: 5px;
            height: 40px;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;

            &.active {
              background-color: $primaryColorVibrant;
            }
          }

          .staff-image-container {
            background-color: #eee;
            border-radius: 50%;
            height: 44px;
            width: 44px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            margin-right: 7px;

            i {
              align-self: center;
              font-size: 28px;
              cursor: pointer;
              color: #000;
            }
          }

          .staff-details-container {
            flex: 1;
            width: 137px;

            p {
              margin: 0;
              overflow: hidden;
              width: 100%;
              white-space: nowrap;
              text-overflow: ellipsis;

              &.staff-name {
                font-weight: 600;
                margin-bottom: 1px;
              }

              &.staff-appointment-count {
                font-size: 12px;
              }
            }
          }

          @media (max-width: 600px) {
            width: 160px;

            .staff-image-container {
              height: 30px;
              width: 30px;
              margin-right: 7px;

              i {
                font-size: 20px;
              }
            }

            .staff-details-container {
              width: 110px;

              .staff-name {
                font-size: 12px;
                margin-bottom: 2px;
              }

              .staff-appointment-count {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }

  .right-container {
    flex: 1;
    overflow: hidden;

    .date-container {
      overflow-x: auto;
      width: 100%;

      .date-container-header-row {
        display: flex;
        flex-direction: row;

        .time-header-row {

          .time-header {
            background-color: #EAECFB;
            height: 60px;
            width: 55px;
            border-right: 1px solid rgba(0, 0, 0, 0.1);
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          }
        }

        .date-header-row {
          flex: 1;
          min-width: 100px;
          border-right: 1px solid $borderLight;

          &:last-of-type {
            border-right-width: 0;
          }

          .date-header {
            background-color: $primaryColorMedium;
            height: 60px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            border-bottom: 1px solid $borderLight;

            .date-header-inner {

              .date-number {
                margin: 0;
                font-weight: 600;
                font-size: 22px;
                line-height: 22px;
              }

              .date-day {
                margin: 0;
                text-transform: uppercase;
                font-size: 12px;
                color: #767881;
                font-weight: 600;
              }
            }
          }
        }
      }

      .time-container-outer {
        height: calc(100vh - 235px);
        min-height: 500px;
        overflow-y: auto;
        overflow-x: hidden;
        min-width: 755px;

        .time-container {
          display: flex;
          flex-direction: row;

          .time-column {

            .hour-cell {
              width: 55px;
              border-right: 1px solid $borderLight;
              border-bottom: 1px solid $borderLight;
              height: 50px;
              position: relative;

              &:last-of-type {
                border-bottom-width: 0;
              }

              span {
                font-size: 12px;
                text-align: right;
                width: 45px;
                background-color: $primaryColorLight;
                position: absolute;
                left: 0;
                top: -9px;
                padding-right: 3px;
                font-weight: 600;
              }
            }
          }

          .date-columns {
            flex: 1;
            display: flex;
            flex-direction: row;

            .date-column {
              flex: 1;
              min-width: 100px;
              display: flex;
              flex-direction: column;

              .date-time-cell {
                border-right: 1px solid $borderLight;
                border-bottom: 1px solid $borderLight;
                height: 50px;
                position: relative;

                .time-block-select-button {
                  display: block;
                  padding: 0;
                  height: 25%;
                  width: 100%;
                  border-radius: 5px;
                  background-color: rgba(0, 0, 0, 0);

                  &:hover {
                    background-color: rgba(0, 0, 0, 0.05);
                  }
                }

                &:last-of-type {
                  border-bottom-width: 0;
                }

                .appointment-button {
                  position: absolute;
                  overflow: hidden;
                  text-align: left;
                  left: 0;
                  right: 0;
                  z-index: 1;
                  padding: 0px 5px;
                  display: flex;

                  span {
                    font-size: 13px;
                    display: block;
                    width: 100%;
                    line-height: 15px;

                    &.appointment-time {
                      margin-top: 5px;
                      overflow: hidden;
                      width: 100%;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    }
                  }
                }
              }

              &:last-of-type {

                .date-time-cell {
                  border-right-width: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
