@import "../../../../colors";

.TableCell {
  position: relative;
  font-size: 14px;
  padding: 20px;
  border-bottom: 1px solid $borderLight;

  &.action {
    width: 75px;
  }
}
