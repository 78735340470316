@import "../../../../../colors";

.Profile {
  position: relative;

  .input-row {
    display: flex;
    flex-direction: row;

    .input-container {
      &:first-child {
        margin-right: 5px;
      }

      &:last-child {
        margin-left: 5px;
      }

      &.flex-1 {
        flex: 1;
      }

      &.flex-2 {
        flex: 2;
      }
    }
  }

  .update-password-container-outer {
    border: 1px solid #bfbfbf;
    border-radius: 12px;
    overflow: hidden;
    margin-top: 10px;

    .update-password-button {
      background-color: #fff;
      color: #333;
      border-radius: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 0;
      padding: 14px 20px;

      .fas.fa-angle-down {
        -webkit-transition: all .3s;
        transition: all .3s;

        &.up {
          transform: rotate(180deg);
        }
      }
    }

    .update-password-container {
      -webkit-transition: all .3s;
      transition: all .3s;
      height: 0;
      overflow: hidden;
      padding: 0 10px;
  
      &.open {
        border-top: 1px solid #ddd;
        padding-bottom: 10px;
        height: auto;
      }
    }
  }

  button {
    width: 100%;
    margin-top: 10px;
  }
}
