@import "../../../colors";

.DataTable {
  overflow-x: auto;
  border: 1px solid $borderLight;
  border-radius: 20px;

  table {
    position: relative;
    border-collapse: collapse;

    tbody tr:last-of-type td {
      border-bottom-width: 0;
    }

    &.secondary {

      thead tr {
        background-color: $secondaryColorMedium;
      }

      tbody tr:nth-child(odd) {
        background-color: $secondaryColorLight;
      }
    }

    &.success {

      thead tr {
        background-color: $successColorMedium;
      }

      tbody tr:nth-child(odd) {
        background-color: $successColorLight;
      }
    }

    &.primary {

      thead tr {
        background-color: $primaryColorMedium;
      }

      tbody tr:nth-child(odd) {
        background-color: $primaryColorLight;
      }
    }
  }
}
