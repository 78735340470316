.OnboardingStep3 {
  margin-top: 15px;
  
  .top-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;

    h2 {
      font-size: 20px;
      font-weight: 600;
    }

    .add-button-container {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
  }

  p {
    margin-top: 0;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .table-container {
    overflow-y: auto;

    thead {
      font-weight: bold;
    }
  
    td {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  
    input {
      min-width: 150px;
    }
  
    .delete-button {
      height: 44px;
      width: 44px;
      border-radius: 44px;
      padding: 12px;
      background-color: rgba(0, 0, 0, 0.05);
      margin-left: 10px;
  
      .fa-trash {
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  .actions-row {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    a {
      cursor: pointer;
    }
  }
}
