@import "../../../../colors";

.Availability {
  padding: 15px;

  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    h1 {
      font-size: 20px;
      font-weight: 400;
      margin: 0;
    }

    button {
      background-color: #fff;
      color: #18181A;
      font-weight: 400;
      border: 1px solid $borderLight;
      padding: 14px 16px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.03);
      }

      .fas {
        margin-left: 10px;
        font-size: 15px;
      }
    }
  }

  .schedule-container {
    border: 1px solid $borderLight;
    margin-top: 20px;
    border-radius: 20px;
    padding: 20px;
    padding-bottom: 0;

    .permission-message {
      margin-top: 0;
      color: #777777;
      font-size: 12px;
    }

    .schedule-top-row {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;

      .input-container {
        margin-top: 0;
      }

      @media (max-width: 500px) {
        flex-direction: column-reverse;
        align-items: flex-end;

        .input-container {
          width: 100%;
          margin-top: 10px;
        }
      }
    }

    .timezone-message {
      color: #777777;
      font-size: 12px;
    }

    .times-container {
      display: flex;
      flex-direction: row;
      margin-top: 30px;

      .hours-container {
        overflow-y: auto;
        flex: 2;

        .hours-row {
          display: flex;
          flex-direction: row;
          border-top: 1px solid $borderLight;

          .hour-cell {
            padding-top: 15px;
            padding-bottom: 15px;
          }

          input[type="checkbox"] {
            width: auto;
          }

          .active-cell {
            padding-left: 0;
            padding-right: 0;
            width: 30px;
            padding-top: 23px;
          }

          .day-cell {
            font-weight: 600;
            text-transform: uppercase;
            font-size: 14px;
            width: 70px;
            padding-top: 23px;

            &.inactive {
              opacity: 0.5;
            }
          }

          .times-column {

            .time-error {
              color: $dangerColor;
              margin: -10px 0 5px;
              font-size: 12px;
            }

            .times-row {
              display: flex;
              flex-direction: row;
              align-items: center;

              .time-cell {
                width: 160px;

                .time-container {
                  display: flex;
                  flex-direction: row;

                  select {
                    padding: 5px 10px;
                    background: linear-gradient(45deg, transparent 50%, #000 50%), linear-gradient(135deg, #000 50%, transparent 50%), linear-gradient(to right, #fff, #fff);
                    background-position: calc(100% - 21px) calc(1em + -1px), calc(100% - 16px) calc(1em + -1px), 100% 0;
                    background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
                    background-repeat: no-repeat;

                    &:first-of-type {
                      width: 80px;
                      border-right: 0;
                      border-top-right-radius: 0;
                      border-bottom-right-radius: 0;
                    }

                    &:last-of-type {
                      width: 70px;
                      border-top-left-radius: 0;
                      border-bottom-left-radius: 0;
                    }
                  }
                }
              }

              .remove-additional-time {

                button {
                  padding: 0;
                  border-radius: 18px;
                  height: 34px;
                  width: 34px;
                  font-size: 16px;
                  line-height: 16px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: space-around;
                  color: #000;
                  background-color: #fff;

                  &:hover {
                    background-color: rgba(0, 0, 0, 0.03);
                  }

                  &:disabled {
                    background-color: #fff !important;
                    opacity: 0.5;
                  }
                }
              }
            }
          }

          .add-cell {
            flex: 1;
            display: flex;
            flex-direction: row-reverse;
            padding-top: 17px;
            margin-right: 10px;

            button {
              padding: 0;
              border-radius: 18px;
              height: 34px;
              width: 34px;
              font-size: 16px;
              line-height: 16px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-around;
              color: #000;
              background-color: #fff;

              &:hover {
                background-color: rgba(0, 0, 0, 0.03);
              }

              &:disabled {
                background-color: #fff !important;
                opacity: 0.5;
              }

              span {
                display: none;
              }
            }
          }

          @media (max-width: 600px) {
            display: block;
            
            .active-cell, .day-cell {
              display: inline-block;
              padding-bottom: 0;
            }

            .add-cell {
              flex-direction: row;
              padding-top: 0;

              button {
                background-color: #fff;
                color: #18181A;
                font-weight: 400;
                border: 1px solid $borderLight;
                padding: 14px 16px;
                height: auto;
                width: auto;
                display: flex;
                flex-direction: row;
                border-radius: 12px;
          
                &:hover {
                  background-color: rgba(0, 0, 0, 0.03);
                }
          
                .fas {
                  margin-left: 10px;
                  font-size: 15px;
                }

                span {
                  display: inherit;
                }
              }
            }
          }
        }
      }

      .date-overrides {
        border-top: 1px solid $borderLight;
        border-left: 1px solid $borderLight;
        flex: 1;
        padding: 20px;
        padding-right: 0;

        .date-overrides-title {
          font-weight: bold;
          margin-top: 5px;
        }

        .date-overrides-description {
          color: #777777;
          font-size: 12px;
        }

        .add-date-override-button {
          width: 100%;
          background-color: #fff;
          color: #18181A;
          font-weight: 400;
          border: 1px solid $borderLight;
          padding: 14px 16px;
    
          &:hover {
            background-color: rgba(0, 0, 0, 0.03);
          }
    
          .fas {
            margin-left: 10px;
            font-size: 15px;
          }
        }

        .date-override-item {
          border: 1px solid $borderDark;
          border-radius: 12px;
          margin-top: 15px;
          padding: 10px;

          .date-override-headers {
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid $borderLight;

            p {
              flex: 1;
              margin: 5px 0 3px 5px;
              font-weight: 600;
              border-right: 1px solid #fff;
              font-size: 14px;
            }
          }

          .date-override-details {
            display: flex;
            flex-direction: row;

            .date-override-column {
              flex: 1;
              margin-left: 5px;

              &:first-of-type {
                border-right: 1px solid $borderLight;
              }

              p {
                font-size: 12px;
                margin: 7px 0;
              }
            }
          }

          .actions-row {
            display: flex;
            flex-direction: row-reverse;

            button {
              padding: 0;
              border-radius: 18px;
              height: 34px;
              width: 34px;
              font-size: 16px;
              line-height: 16px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-around;
              color: #000;
              background-color: #fff;
              margin-left: 3px;

              &:hover {
                background-color: rgba(0, 0, 0, 0.03);
              }

              &:disabled {
                background-color: #fff !important;
                opacity: 0.5;
              }

              span {
                display: none;
              }
            }
          }
        }
      }

      @media (max-width: 860px), (max-width: 1000px) and (min-width: 901px) {
        flex-direction: column;

        .date-overrides {
          border-left-width: 0;
          padding: 20px 0;
        }
      }
    }
  }

  .delete-schedule-button-container {
    margin-top: 20px;
    display: flex;
    flex-direction: row-reverse;

    button {
      
      .fas {
        margin-left: 10px;
        font-size: 15px;
      }
    }
  }
}

.date-overrides-modal {

  .date-overrides-modal-description {
    margin-top: 0;
    color: #777777;
    text-align: center;
  }

  .date-overrides-modal-calendar-picker-container {
    width: 100%;
    border: 1px solid $borderLight;
    border-radius: 20px;
    padding: 10px;

    .react-calendar {
      background-color: #fff;
      border-radius: 10px;
      padding: 10px;
      max-width: 350px;
      min-width: 280px;
      margin-left: auto;
      margin-right: auto;

      button {
        padding: 0;
        margin: 0;
        background-color: rgba(0, 0, 0, 0);
        color: #0A0A0A;
      }

      .react-calendar__navigation {
        display: flex;
        flex-direction: row;
        padding-left: 8px;
        padding-right: 8px;
        width: calc(100% - 15px);
        max-width: 307px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 25px;

        .react-calendar__navigation__prev2-button,
        .react-calendar__navigation__next2-button {
          display: none;
        }

        .react-calendar__navigation__label span {
          font-weight: 600;
          font-size: 16px;
        }

        .react-calendar__navigation__arrow i {
          font-size: 14px;
        }
      }

      .react-calendar__viewContainer .react-calendar__month-view {
        margin-top: 10px;

        .react-calendar__month-view__weekdays {
          text-align: center;
          margin-bottom: 3px;
          padding-bottom: 3px;
          border-bottom: 1px solid #F5F5F5;

          abbr {
            font-size: 0;
            display: block;
            font-weight: 600;
            text-decoration: none;

            &:first-letter {
              font-size: 12px;
            }
          }
        }

        .react-calendar__month-view__days {

          button {
            font-size: 12px;
            border-radius: 50%;
            height: 45px;

            &:hover {
              background-color: rgba(0, 0, 0, 0.05);
            }

            &.date-selected-for-override {
              background-color: $primaryColorVibrant;
              color: #fff;

              &:hover {
                background-color: $primaryColorVibrant;
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  .date-overrides-modal-select-hours-container {
    margin-top: 25px;

    .time-entry-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 5px 0;

      .times-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;

        .time-cell {
          width: 100%;

          .time-container {
            display: flex;
            flex-direction: row;

            select {
              padding: 5px 10px;
              background: linear-gradient(45deg, transparent 50%, #000 50%), linear-gradient(135deg, #000 50%, transparent 50%), linear-gradient(to right, #fff, #fff);
              background-position: calc(100% - 21px) calc(1em + -1px), calc(100% - 16px) calc(1em + -1px), 100% 0;
              background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
              background-repeat: no-repeat;

              &:first-of-type {
                flex: 2;
                min-width: 80px;
                border-right: 0;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
              }

              &:last-of-type {
                flex: 1;
                min-width: 70px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                margin-right: 10px;
              }
            }
          }
        }

        @media (max-width: 370px) {
          flex-direction: column;
          flex: 1;

          .time-cell {
            margin-top: 4px;
            margin-bottom: 4px;
          }
        }
      }

      .delete-container {

        button {
          padding: 0;
          border-radius: 18px;
          height: 34px;
          width: 34px;
          font-size: 16px;
          line-height: 16px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          color: #000;
          background-color: #fff;

          &:hover {
            background-color: rgba(0, 0, 0, 0.03);
          }

          &:disabled {
            background-color: #fff !important;
            opacity: 0.5;
          }

          span {
            display: none;
          }
        }
      }
    }

    .time-error {
      color: $dangerColor;
      margin: -5px 0 0;
      font-size: 12px;
    }

    .add-availability-container {
      margin-top: 15px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .unavailable-message-container {
        flex: 1;

        p {
          color: #777777;
          font-weight: 600;
        }
      }

      .add-override-times-button-container {
        flex: 1;

        button {
          background-color: #fff;
          color: #18181A;
          font-weight: 400;
          border: 1px solid $borderLight;
          padding: 14px 16px;
          width: 100%;

          &:hover {
            background-color: rgba(0, 0, 0, 0.03);
          }

          .fas {
            margin-left: 10px;
            font-size: 15px;
          }
        }
      }
    }
  }
}
