.StaffActionDropdown {
  background-color: #fff;
  margin-right: 16px;
  width: 150px;
  padding: 8px 0;
  box-shadow: 2px 10px 60px rgba(0, 0, 0, 0.15);
  border-radius: 15px;

  button {
    width: 100%;
    text-align: left;
    padding: 7px 20px;
    background-color: #fff;
    color: #000;
    font-size: 14px;
    font-weight: 400;

    &:hover {
      background-color: rgba(0, 0, 0, 0.03);
    }
  }
}
