@import "../../../../colors";

.Calendar {
  padding: 15px;
}

.appointment-modal {
  margin-top: -25px;
  margin-bottom: -20px;

  .date-picker-button {
    color: #404040;
    background-color: #fff;
    border: 1px solid #bfbfbf;
    text-align: left;
    width: 100%;
    padding: 14px 20px;
    font-size: 17px;
  }

  .form-error {
    color: $dangerColor;
  }

  .times-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .time-divider {
      height: 23px;
      width: 7px;
      margin-left: 3px;
      margin-right: 3px;
    }

    .time-container {
      flex: 1;
    }

    @media (max-width: 400px) {
      flex-direction: column;

      .time-divider {
        height: 10px;
        width: 0;
        margin-left: 0;
        margin-right: 0;
      }

      .time-container {
        width: 100%;
      }
    }
  }

  .color-and-recurring-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 0;

    .color-container-input {
      flex: 1;
    }

    .recurring-row {
      display: flex;
      flex-direction: row;
      flex: 1;

      input {
        margin-left: 10px;
        margin-right: 10px;
      }

      label {
        margin-right: 7px;
        font-weight: 600;
      }

      .recurring-tip-container {
        position: relative;
        height: 19px;
        border-radius: 10px;

        i {
          margin-top: 4px;
          font-size: 15px;
          color: #6f6f6f;
          position: relative;
        }

        .recurring-tip {
          position: absolute;
          display: none;
          background-color: rgba(0, 0, 0, 0.85);
          z-index: 1;
          color: #fff;
          padding: 5px;
          border-radius: 4px;
          font-size: 12px;
          line-height: 16px;
          width: 200px;
          right: 20px;
          top: -10px;
        }

        &:hover {

          .recurring-tip {
            display: inherit;
          }
        }
      }
    }
  }

  .combined-select-container {
    display: flex;
    flex-direction: row;
    flex: 1;

    select {
      flex: 1;

      &:first-of-type {
        width: 80px;
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-of-type {
        width: 70px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .frequency-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .before-container {
      margin-left: 9px;
      margin-right: 3px;

      label {
        font-weight: 600;
      }
    }

    .ongoing-row {
      display: flex;
      flex-direction: row;
      padding-top: 8px;
      margin-left: 15px;
      align-items: center;

      input {
        margin-left: 10px;
        margin-right: 10px;
      }

      label {
        margin-right: 7px;
        font-weight: 600;
      }

      .ongoing-tip-container {
        position: relative;
        height: 19px;
        border-radius: 10px;

        i {
          font-size: 15px;
          color: #6f6f6f;
          position: relative;
        }

        .ongoing-tip {
          position: absolute;
          display: none;
          background-color: rgba(0, 0, 0, 0.85);
          z-index: 1;
          color: #fff;
          padding: 5px;
          border-radius: 4px;
          font-size: 12px;
          line-height: 16px;
          width: 240px;
          right: 20px;
          top: -10px;
        }

        &:hover {

          .ongoing-tip {
            display: inherit;
          }
        }
      }
    }
  }

  .select-new-customer-container {

    .input-container {
      flex: 1;
    }

    .customer-or-container {
      text-align: center;

      p {
        color: #777777;
        margin: 15px 0 0;
        font-size: 14px;
      }
    }

    .create-customer-button-container {
      text-align: center;

      button {
        padding: 0;
        background-color: #fff;
        color: #000;
        text-decoration: underline;
        font-size: 14px;
        margin-bottom: 10px;
      }
    }
  }

  .create-new-customer-container {
    border: 1px solid $borderDark;
    border-radius: 12px;
    padding: 20px;

    .create-new-customer-header-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;

      .create-new-customer-header {
        font-weight: 600;
      }

      a {
        padding: 0;
        background-color: #fff;
        color: #000;
        text-decoration: underline;
        font-size: 14px;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .notifications-container {
    border: 1px solid $borderDark;
    border-radius: 12px;
    padding: 0 20px;
    margin-top: 12px;


    .appointment-notification-container {
      border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
      padding: 10px 0;

      &:last-of-type {
        border-bottom-width: 0;
      }

      p {
        font-weight: 600;
        font-size: 14px;
        margin: 0;
        margin-top: 0;
      }

      .appointment-notification-selection {
        margin: 10px 0 5px;
        display: flex;
        flex-direction: row;
        align-items: center;

        label {
          margin-left: 10px;
          margin-right: 30px;
          font-weight: 600;
        }
      }
    }
  }
}

.view-event-modal {
  margin-bottom: -35px;

  .customer-name {
    font-size: 22px;
    margin: 0;
  }

  .date {
    margin-top: 7px;
    margin-bottom: 13px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .2px;
    line-height: 20px;
    color: #333;

    .date-divider {
      height: 3px;
      width: 3px;
      border-radius: 3px;
      background-color: #333;
      display: inline-block;
      margin-bottom: 3px;
      margin-left: 8px;
      margin-right: 8px;
    }

    .time-divider {
      margin-left: 3px;
      margin-right: 3px;
    }
  }

  .detail {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .2px;
    line-height: 20px;
    color: #333;
    margin: 10px 0;

    &.notes {
      margin-top: -10px;
    }

    strong {
      font-weight: 600;
    }
  }

  .actions-buttons-container {
    display: flex;
    flex-direction: row-reverse;

    .action-button {
      height: 44px;
      width: 44px;
      border-radius: 44px;
      padding: 12px;
      background-color: rgba(128, 57, 57, 0.04);
      margin-left: 7px;
  
      .fa {
        color: rgba(0, 0, 0, 0.8);
      }
    }
  }
}

.appointment-delete-modal {

  .recurring-appointment-options {

    span {
      display: flex;
      flex-direction: row;
      margin: 10px 0;

      input {
        width: auto;
        margin-right: 15px;
      }
    }
  }

  .message {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .2px;
    line-height: 20px;
    color: #333;
  }
}
